.regeneratePinButton:hover {
    cursor: pointer;
}

.exportToPdfButton {
    background: none;
    border: none;
}

.exportToPdfButton:hover {
    cursor: pointer;
}
