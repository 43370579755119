.invisibleButton {
    background: none;
    border: none;
}

.invisibleButton:hover {
    background: none;
    border: none;
    cursor: pointer;
}

.invisibleButton:focus {
    background: none;
    border: none;
}